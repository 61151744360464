<template>
  <div id="transactions" class="main-content">
    <div class="panel-wrapper" ref="panelWrapper">
      <div style="width: 100%;">
        <h1 class="headline">
          Transactions
        </h1>

        <div id="transactions-filter-form">
          <form-group label="Wallet" class="form-group-wallets">
            <multiselect
              v-model="form.wallet"
              :options="balancesGrouped"
              :class="{'valid': form.wallet, 'notranslate': true}"
              :show-labels="false"
              :group-select="false"
              :allow-empty="false"
              group-values="items"
              group-label="title"
              track-by="id"
              label="title"
            >
              <template #caret >
                <div>
                  <div
                      v-if="blockedBalances.length"
                      v-tooltip="{content: `Switch to ${isLocked ? 'blocked' : 'active'} wallets`}"
                      class="multiselect-addon multiselect-addon-lock"
                      :class="{hidden: isLocked}"
                      @mousedown.prevent="isLocked = !isLocked"
                  />
                  <div class="multiselect__select" />
                </div>
              </template>
              <template #option="{ option }">
                <div v-if="option.$isLabel" content="option__currency-label">
                  <flag class="option__currency-label-img" :name="option.$groupLabel" />
                  <span class="option__currency-label-text">{{ option.$groupLabel }}</span>
                </div>
                <div v-else class="option__currency">
                  <span class="option__currency-left notranslate">
                    {{ option.title }}
                  </span>
                  <span class="option__currency-right">
                    Balance: {{ option.amount | numeral('0,0.00') }} {{ option.currency.id }}
                  </span>
                </div>
              </template>
              <template #singleLabel="{ option }">
                <div class="option__currency">
                  <span class="option__currency-left">
                    {{ option.title }}
                  </span>
                </div>
              </template>
            </multiselect>
          </form-group>

          <form-group label="Type">
            <multiselect
              v-model="form.type"
              :options="types"
              :class="{'valid': form.type, 'notranslate': true}"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              :preselect-first="true"
              label="label"
            />
          </form-group>

          <form-group label="Fees">
            <multiselect
                v-model="form.fees"
                :options="feeListOptions"
                :class="{'valid': form.fees}"
                :show-labels="false"
                :searchable="false"
                :allow-empty="false"
                label="label"
            />
          </form-group>

          <form-group label="Date range:" class="form-group-date">
            <datepicker
              v-model="form.range"
              value-format="format"
              format="DD-MM-YYYY"
              placeholder="Choose a date"
              :lang="lang"
              :class="{'valid': form.range.length}"
              popup-class="notranslate"
              :shortcuts="shortcuts"
              range
            />
          </form-group>

          <form-group label=" ">
            <export-button :permissions="exportPermissions" :disabled="displaySaveLink" @export="getDownloadReportLink" />
          </form-group>
        </div>
      </div>
    </div>

    <div class="inner-container">
      <div class="table-content scrollbar-hide" style="height: calc(100vh - 252px);" ref="table">
        <table class="common-table" style="margin-bottom: 0">
          <thead class="table-head">
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Counterparty</th>
              <th>Reference</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody class="table-body notranslate">
            <tr v-for="(payment, index) in payments" :key="index" @click="openDetailsModal(payment.id)">
              <td class="date">
                <span>{{ payment.completed_at | moment('DD-MM-YYYY') }}</span>
              </td>
              <td class="type" :class="getTrColor(payment)">
                {{ payment.type }}
              </td>
              <td>
                <div class="counterparty-holder-name">
                  {{ payment.counterparty_name }}
                </div>
                <div class="counterparty-iban">
                  {{ payment.counterparty.iban }}
                </div>
              </td>
              <td>{{ payment.reference }}</td>
              <td :class="getTrColor(payment)">
                {{ payment.amount }} {{ payment.currency_id }}
              </td>
            </tr>
          </tbody>
        </table>

        <infinite-loading :identifier="identifierFormData" @infinite="loadMore">
          <template #no-more>
            <div class="no-transactions-more">
              No more transactions
            </div>
          </template>
          <template #no-results>
            <div class="no-transactions-results">
              Transactions not found
            </div>
          </template>
          <template #error>
            <div class="no-transactions-results">
              {{ isWalletSelected ? 'Something was wrong 😔' : 'Please choose the wallet'}}
            </div>
          </template>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentDetailsModal from './modals/PaymentDetailsModal'
import FormGroup from '../libs/forms/FormGroup'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vue2-datepicker'
import {mapGetters, mapState} from 'vuex'
import ExportButton from "@/components/transaction/ExportButton";
import InfiniteLoading from 'vue-infinite-loading';
import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'

import { isEqualInt } from "../libs/functions";
import Flag from "../libs/Flag";

export default {
  name: 'Transaction',
  components: {
    Flag,
    ExportButton,
    FormGroup,
    Multiselect,
    Datepicker,
    InfiniteLoading
  },
  data () {
    return {
      /** Config for DatePicker **/
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      },

      isLocked: true,
      shortcuts: [],

      form: {
        fees: null,
        wallet: null,
        range: [],
        type: null
      },
      types: [
        {
          id: undefined,
          'label': 'All'
        },
        {
          id: 'debit',
          label: 'Debit'
        },
        {
          id: 'credit',
          label: 'Credit'
        }
      ],

      dateStringFormat: 'DD-MM-YYYY',
    }
  },
  sockets: {
    // Update only if we get event for item chose in wallet select
    updatePayment: debounce(function ({ status, wallet_id }) {
      if (
          status === 'completed' &&
          this.form.wallet &&
          isEqualInt(this.form.wallet.id, wallet_id)
      ) {
        this.reset()
      }
    }, 5000)
  },
  watch: {
    formData() {
      this.$store.dispatch('transactions.reload')
    }
  },
  computed: {
    ...mapState({
      page: state => state.transaction.page,
      payments: state => state.transaction.items,
      identifier: state => state.transaction.identifier
    }),
    ...mapGetters({
      activeBalances: 'getActiveBalances',
      blockedBalances: 'getBlockedBalances'
    }),
    displaySaveLink () {
      return !!(this.form.wallet && this.form.wallet.id && this.canExport && this.form.range[0] && this.form.range[1])
    },
    canExport() {
      return Object.values(this.exportPermissions).some(permission => permission === true)
    },
    exportPermissions() {
      const permissions = {
        canExportCSV: false,
        canExportPDF: false,
        canExportXML: false
      }

      if (this.form.wallet) {
        const walletPermissions = this.form.wallet && this.form.wallet.permissions

        for (const key in permissions) {
          if (Object.prototype.hasOwnProperty.call(walletPermissions, key)) {
            permissions[key] = walletPermissions[key]
          }
        }
      }

      return permissions
    },
    isWalletSelected () {
      return !!this.form.wallet
    },
    formData () {
      const dateStart = this.toDateFormatter(this.form.range[0])
      const dateFinish = this.toDateFormatter(this.form.range[1])
      const walletId = this.form.wallet ? this.form.wallet.id : ''
      const type = this.form.type ? this.form.type.id : ''
      const fees = this.form.fees ? this.form.fees.id : ''

      return {
        dateFrom: dateStart,
        dateTo: dateFinish,
        wallet_id: walletId,
        type: type,
        include_fee_payment: fees
      }
    },
    identifierFormData() {
      return {
        data: this.formData,
        identifier: this.identifier
      }
    },
    balancesGrouped () {
      const data = []
      const list = this.isLocked ? this.activeBalances : this.blockedBalances
      const items = groupBy(list, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },

    feeListOptions () {
      return [
        {
          id: 1,
          label: 'Including'
        },
        {
          id: 0,
          label: 'Excluding'
        }
      ]
    }
  },
  created () {
    const balancesSorted = [...this.activeBalances, ...this.blockedBalances]

    if (balancesSorted && balancesSorted.length > 0) {
      this.form.wallet = balancesSorted[0]
    }

    // Preselect fees
    this.form.fees = this.feeListOptions.find(item => item.id === 1)
    //
    // Preselect dates (Default last 3 months)
    this.form.range[0] = this.$moment().subtract(3, 'months')._d
    this.form.range[1] = this.$moment()._d
  },
  methods: {
    openDetailsModal (id) {
      this.$modal.show(PaymentDetailsModal, { id: id })
    },
    getTrColor (payment) {
      return {
        'red-colored': payment.type === 'debit',
        'yellow-colored': payment.type === 'credit'
      }
    },
    reset () {
      this.$store.dispatch('transactions.reload')
    },
    loadMore: function ($state) {
      if (!this.isWalletSelected) {
        $state.error()
        return
      }

      this.$store.dispatch('transactions.more', this.formData).then(({ headers, data }) => {
        if (!data) {
          return
        }

        if (data.length > 0) {
          $state.loaded()
        }

        let currentPage = parseInt(headers['x-pagination-current-page'])
        let maximumPage = parseInt(headers['x-pagination-page-count'])

        if (currentPage >= maximumPage) {
          $state.complete()
        }
      }).catch(reason => {
        $state.error()

        if (reason.response && reason.response.data && reason.response.data.message) {
          this.$notice.error(reason.response.data.message)
        }
      })
    },
    getDownloadReportLink (event, format) {
      this.$sdk.wallet.report.create(this.form.wallet.id, format, this.formData).catch(({ response }) => {
        if (response.data.message) {
          this.$notice.error(response.data.message, {
            timeout: 10000
          })
        }
      })
    },
    toDateFormatter (value) {
      if (value) {
        return this.$moment(value).format(this.dateStringFormat)
      }

      return undefined
    }
  }
}
</script>

<style lang="less">
.form-group-wallets .multiselect__input {
  padding-right: 60px;
}

.no-transactions {
  &-results {
    border: 1px solid #3f3f3f;
    border-top: none;
    padding: 15px;
    font-size: 14px;
  }
  &-more {
    font-size: 14px;
    color: #666;
    padding: 25px 15px;
  }
}
.counterparty-holder-name {
  overflow-x: hidden;
  text-overflow: ellipsis;

  &:hover {
    overflow-x: scroll;
    text-overflow: unset;

    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
}
.counterparty-iban {
  margin-top: 10px;
  color: #666;
}
</style>

<style lang="less" scoped>
#transactions-filter-form {
  .form-group-date {
    min-width: 230px;
  }
}

.common-table td {
  &.date {
    width: 110px;
  }
  &.type {
    width: 70px;
  }

}
</style>
