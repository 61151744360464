<template>
  <div v-click-outside="close">
    <button :disabled="!disabled" @click="toggle" class="btn export highlighted" :class="{'disabled':!disabled}" style="white-space: nowrap; margin: 0;">
      Export to
    </button>

    <slide-y-up-transition :duration="150">
      <ul class="list-formats" v-if="show">
        <li v-if="permissions.canExportPDF" @click="$emit('export', $event, 'pdf')">.pdf</li>
        <li v-if="permissions.canExportCSV" @click="$emit('export', $event, 'csv')">.csv</li>
        <li v-if="permissions.canExportXML" @click="$emit('export', $event, 'xml')">.xml</li>
      </ul>
    </slide-y-up-transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { SlideYUpTransition } from 'vue2-transitions'
export default {
  name: 'ExportButton',
  components: {
    SlideYUpTransition
  },
  directives: {
    ClickOutside
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    changeShowValue () {
      this.show = !this.show
    },
    close () {
      this.show = false
    },
    toggle () {
      this.show = !this.show
    },
  }
}
</script>

<style lang="less">

.list-formats {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #19191C;
  box-shadow: -4px -7px 13px 0 rgb(0 0 0 / 15%), 0 4px 9px 0 rgb(0 0 0 / 50%);
  transition: all 0.3s ease;
  right: 0;
  margin-top: 20px;
  width: 100%;

  &:before {
     position: absolute;
     content: ' ';
     width: 12px;
     height: 12px;
     transform: rotate(45deg);
     display: block;
     background: #1C1C20;
     border: 1px solid rgba(255, 255, 255, 0.3);
     z-index: 1;
     top: -7px;
     right: 50%;
     margin-right: -9px;
     border-bottom: 1px solid transparent;
     border-right: 1px solid transparent;
   }

  li {
    padding: 14px 14px 14px 14px;
    cursor: pointer;
    margin: 6px 0 5px;
    position: relative;
    display: inline-block;
    width: 100%;

    &:hover {
       color: #fde46a;
       background: rgba(255, 255, 255, 0.03);
    }

    &:before {
      content: ' ';
      position: absolute;
      top: -6px;
      left: 4px;
      right: 4px;
      width: auto;
      height: 1px;
      background: rgba(255, 255, 255, 0.09);
    }

    &:first-child:before {
      display: none;
    }
  }
}
</style>
